<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Raporlar'" class="mr-2 text-white"></label>
      </template>
      <download-excel :fetch="getClubReport" class="ml-2 mb-4"
        ><b-btn variant="primary" href="#"> Kulüp Yarışmacı Sayıları </b-btn></download-excel
      >
      <b-list-group>
        <b-list-group-item v-for="(item, index) in titles" :key="index">
          <div v-if="item.route">
            <b-badge variant="secondary">{{ index + 1 }}</b-badge>
            <router-link
              :to="item.route"
              style="margin-left: 1rem; color: black"
              >{{ item.title }}</router-link
            >
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-container>
</template>

<script>
import reportHelper from "@/store/helpers/PMSReportHelper";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    "download-excel": JsonExcel,
  },
  data() {
    return {
      titles: [{ title: "Kulüp Raporu", route: "/clubReport" }],
    };
  },
  methods: {
    async getClubReport() {
      const result = await reportHelper.getPdfItemsFromServer(
        "clubCompetitionPersonelCount",
        {}
      );
      return result;
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>